@at-root body[data-browser = 'es5'] {
  .row {
    display: flex;
    display: -webkit-flex;
  }

  .es5-d-flex {
    display: -webkit-flex;
    display: flex;
  }

  .es5-justified-content-center {
    justify-content: center;
    -webkit-justify-content: center;
  }

  .es5-align-items-center {
    align-items: center;
    -webkit-align-items: center;
  }
}
